<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"></Breadcrumb>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>订单搜索：</label>
            <el-input v-model="params.orderNo" placeholder="订单搜索" clearable></el-input>
          </el-row>
          <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
          <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-row type="flex" justify="space-between">
          <el-tabs @tab-click="handleTab" v-model="params.status">
            <el-tab-pane label="全部" name=""></el-tab-pane>
            <el-tab-pane v-for="(item, index) in this.dictData['refund-apply-status']" :label="item" :name="index" :key="index"></el-tab-pane>
          </el-tabs>
        </el-row>
        <el-table v-loading="loading" :data="tableData" style="width: 100%" :stripe="true" border fit>
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column prop="orderNo" label="订单编号" min-width="50"></el-table-column>
          <el-table-column label="商品类型" min-width="70">
            <template slot-scope="scope">
              {{ scope.row.orderType | filterOrderType(that) }}
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" min-width="70"></el-table-column>
          <el-table-column prop="mobile" label="下单电话" min-width="70"></el-table-column>
          <el-table-column prop="actualAmount" label="实收金额"></el-table-column>
          <el-table-column prop="orderTime" label="申请退款时间"></el-table-column>
          <el-table-column label="订单状态">
            <template slot-scope="scope">
              {{ scope.row.status | filterStatus(that) }}
            </template>
          </el-table-column>
          <el-table-column label="供应酒店" prop="srcHotelName"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text">
                <el-link @click="handleReason(scope.row)" type="primary">查看原因</el-link>
              </el-button>
              <span class="m-left-10 m-right-10" v-if="scope.row.status === 'APPLY' || scope.row.status === 'FAIL'">
                <el-button @click="handleRefund('agree', scope.row.id)" type="text">
                  <el-link type="primary">同意</el-link>
                </el-button>
                <el-button @click="handleRefund('reject', scope.row.id)" type="text">
                  <el-link type="danger">拒绝</el-link>
                </el-button>
              </span>
              <el-button @click="handleDetail(scope.row)" type="text">
                <el-link type="primary">详情</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
      </el-row>
    </div>
    <el-dialog title="退款原因" :visible.sync="visibleReason" width="550px">
      <el-descriptions title="" :column="1">
        <el-descriptions-item label="原因类型">{{ reasonType }}</el-descriptions-item>
        <el-descriptions-item label="原因">{{ reason }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer"><el-button @click="visibleReason = false">关闭</el-button></span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, agreeRefund } from "@/api/shop/order/afterSales";
import { getDict } from "@/common/js/common";
import { mapState } from "vuex";
export default {
  name: 'afterSalesCenter',
  data() {
    return {
      that: this,
      crumbs: new Map([
        ['特惠商城'], ['订单中心'], ['售后中心']
      ]),
      params: {
        orderNo: '',
        status: '',
        dataScope: 'MALL'
      },
      total: 0,
      limit: 0,
      page: 1,
      loading: false,
      tableData: [],
      visibleReason: false,
      reasonType: '',
      reason: ''
    }
  },
  computed: { ...mapState(['hotelInfo', 'dictData']) },
  mounted() {
    getDict(['refund-apply-status', 'order-type'])
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getRefundList()
  },
  methods: {
    // 获取退款列表
    getRefundList() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
          params = { ...this.params, hotelId: this.hotelInfo.id };
      params.status === '0' && (params.status = '')
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(query, params).then(({ success, records, total }) => {
        if (!success) return
        this.loading = false
        this.total = total
        this.tableData = records
      })
    },
    // 处理查询
    handleQuery(bool) {
      if (bool) return this.getRefundList()
      for (const k in this.params) this.params[k] = ''
    },
    // 改变每页数
    changePagNum(num) {
      this.limit = num
      this.getRefundList()
    },
    // 改变当前页
    changeCurrPage(num) {
      this.page = num
      this.getRefundList()
    },
    // 改变当前tab
    handleTab() {
      this.page = 1
      this.getRefundList()
    },
    // 处理退款
    handleRefund(type, id) {
      this.$confirm(`确定${ type === 'agree' ? '同意' : '拒绝' }退款？`, '提示', {
        confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
      }).then(_ => {
        this.loading = true
        agreeRefund(type, { id }).then(({ success, message }) => {
          if (success) {
            this.getRefundList()
            this.$message({ message: '处理成功！', type: 'success' })
          }else this.$message({ message, type: 'error' })
        }).finally(() => this.loading = false)
      }).catch(_ => {})
    },
    // 查看原因
    handleReason({ reason, reasonType }) {
      this.visibleReason = true
      this.reason = reason
      this.reasonType = reasonType
    },
    // 处理详情
    handleDetail({ orderNo }) {
      this.$router.push({ path: '/preferential_mall/order_manage/detail', query: { orderNo } })
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getRefundList()
    }
  },
  filters: {
    filterStatus(val, that) {
      if (val && that.dictData['refund-apply-status']) return that.dictData['refund-apply-status'][val] || val
    },
    filterOrderType(val, that) {
      if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
